import { isNavLinkActive } from "@core/layouts/utils";
import { ref } from "vue";

export default function useHorizontalNavMenuHeaderGroup(item) {
  // ------------------------------------------------
  // isActive
  // ------------------------------------------------
  const isActive = ref(false);

  const updateIsActive = () => {
    isActive.value = isNavLinkActive(item);
  };

  return {
    isActive,
    updateIsActive,
  };
}
